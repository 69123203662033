<template>
  <div class="beianHao">
    <a href="https://beian.miit.gov.cn/">豫ICP备2023032545号-1</a>
  </div>
  <div class="head">
    <div class="dropdown">
      <button class="dropbtn">图鉴</button>
      <div class="dropdown-content">
        <a @click="goOtherPage('MyPhoto')">昆虫图鉴</a>
        <a @click="goOtherPage('InsectKey')">检索表</a>
      </div>
    </div>

    <div class="dropdown" v-show="$store.state.pms_02 == '1'">
      <button class="dropbtn">襀翅目</button>
      <div class="dropdown-content">
        <a @click="goOtherPage('PlecopteraImg')">襀翅照片</a>
        <a @click="goOtherPage('PlecoList')">襀翅名录</a>
      </div>
    </div>

    <div
      v-show="$store.state.pms_04 == '1'"
      class="dropbtn"
      @click="goOtherPage('ImgArchives')"
    >
      档案馆绘图
    </div>
    <div class="dropdown">
      <button class="dropbtn">资料</button>
      <div class="dropdown-content">
        <a @click="goOtherPage('WebPhoto')">图片素材</a>
        <a @click="goOtherPage('ThreeList')">3D模型</a>
        <a @click="goOtherPage('BookList')">书籍</a>
        <a @click="goOtherPage('PdfNoteList')">pdf笔记</a>
        <a @click="goOtherPage('VideoData')">视频</a>
        <a @click="goOtherPage('MusicData')">音乐</a>
      </div>
    </div>

    <div class="dropbtn" @click="goOtherPage('WebCollection')">网页收藏</div>
    <div class="dropbtn" @click="goOtherPage('ShortcutKey')">软件快捷键</div>

    <div class="dropdown">
      <button class="dropbtn">商城</button>
      <div class="dropdown-content">
        <a
          @click="
            goOtherPage(
              'shoppingList',
              '尧彩新_标本商品',
              '小程序标本商品目录_kilala'
            )
          "
          >标本摆件</a
        >
        <a @click="goOtherPage('shoppingList', '绘画作品商品', '绘画作品')"
          >绘画摆件</a
        >
        <a
          @click="
            goOtherPage('shoppingList', '活体昆虫商品', '活体昆虫_kilala')
          "
          >活体昆虫</a
        >
        <a @click="goOtherPage('PrintLabel')">二维码标签</a>
      </div>
    </div>
    <div class="dropdown">
      <button class="dropbtn">个人作品</button>
      <div class="dropdown-content">
        <a @click="goOtherPage('DrawingInk')">钢笔画</a>
        <a @click="goOtherPage('DrawingDigital')">板绘</a>
        <a @click="goOtherPage('ThreeImg')">环物</a>
        <a @click="goOtherPage('ThreeMuseum')">虚拟画廊</a>
        <a @click="goOtherPage('PrizeDraw')">抽奖</a>
        <!-- <a @click="goOtherPage('GenshinA')">原神角色图鉴</a> -->
        <!-- <a @click="goOtherPage('ShowZip')">压缩版</a> -->

        <!-- <a @click="goOtherPage('DrawingAi')">AI绘画</a> -->
      </div>
    </div>
    <div class="dropbtn" @click="goOtherPage('DownloadFile')">下载</div>
    <div class="dropbtn" @click="goOtherPage('AboutMe')">关于</div>
    <div class="dropdown" v-show="$store.state.userName == '尧彩新'">
      <button class="dropbtn">私人数据</button>
      <div class="dropdown-content">
        <a @click="goOtherPage('MyDrawing')">绘画作品</a>
        <a
          v-show="$store.state.pms_02 == '1'"
          @click="goOtherPage('UploadData')"
          >上传数据</a
        >
        <a @click="goOtherPage('PriceList')">询价表</a>
        <a @click="goOtherPage('Copywriting')">文案</a>
        <a @click="goOtherPage('UpList')">感兴趣博主</a>
        <a @click="goOtherPage('VideoLocal')">本地视频</a>
        <a @click="goOtherPage('AudioLocal')">本地音频</a>
        <a @click="goOtherPage('VideoClipNote')">视频剪辑笔记</a>
        <a @click="goOtherPage('EncryptedData')">加密数据</a>
        <a @click="goOtherPage('PrivateGallery')">私人图库</a>
        <a @click="goOtherPage('DeleteData')">删除数据</a>
        <a @click="goOtherPage('UserData')">用户</a>
        <a @click="goOtherPage('ShopMaterial')">雨_店铺美工</a>

        <a @click="goOtherPage('UserData')">网站用户</a>
        <a @click="goOtherPage('ApiData')">接口管理</a>
      </div>
    </div>
    <div class="dropdown" v-show="$store.state.userName == '尧彩新'">
      <button class="dropbtn">导出图片</button>
      <div class="dropdown-content">
        <a @click="goOtherPage('PrintCertificate')">导出证书</a>

        <a @click="goOtherPage('ExportImg')">导出图片(插画)</a>
        <a @click="goOtherPage('ExportImgInk')">导出图片(钢笔画)</a>
        <a @click="goOtherPage('ExportCover')">封面_竖版_绘画</a>
        <a @click="goOtherPage('ExportCoverA')">封面_竖版_素材</a>
        <a @click="goOtherPage('ExportCoverB')">封面_横版_素材</a>
        <a @click="goOtherPage('ExportCoverC')">开奖封面</a>
      </div>
    </div>
    <div class="dropdown">
      <button class="dropbtn" v-show="$store.state.userName != ''">
        成员管理
      </button>
      <div class="dropdown-content">
        <a
          @click="goOtherPage('UploadDataWang')"
          v-show="
            $store.state.userName == '尧彩新' || $store.state.userName == '王超'
          "
          >王超_上传数据</a
        >
        <a
          @click="goOtherPage('WangGoods')"
          v-show="
            $store.state.userName == '尧彩新' || $store.state.userName == '王超'
          "
          >王超_商品列表</a
        >
        <a
          @click="goOtherPage('UploadDataHui')"
          v-show="
            $store.state.userName == '尧彩新' ||
            $store.state.userName == '汪慧娟'
          "
          >汪慧娟_上传数据</a
        >
        <a
          @click="goOtherPage('WebPhotoHui')"
          v-show="
            $store.state.userName == '尧彩新' ||
            $store.state.userName == '汪慧娟'
          "
          >汪慧娟_图库</a
        >
        <a
          @click="goOtherPage('QiSiBoPrice')"
          v-show="
            $store.state.userName == '尧彩新' ||
            $store.state.userName == '齐思博'
          "
          >齐思博_报价表</a
        >
      </div>
    </div>

    <div
      v-show="$store.state.userName == ''"
      class="myName"
      @click="openWindow('open')"
    >
      登录
    </div>
    <div class="myName" @click="openWindow('exit')">
      {{ $store.state.userName }}
    </div>
  </div>
  <div class="mobile_head">
    <div class="box"></div>
  </div>
  <div class="con">
    <router-view :key="$route.fullPath" />
  </div>
  <div class="login_box">
    <div class="login">
      <div class="item">
        <div class="title">用户名：</div>
        <input
          type="text"
          class="login_input"
          v-model="dataToSql.user_name"
          @keyup.enter="login"
        />
      </div>
      <div class="item">
        <div class="title">密码：</div>
        <input
          type="text"
          class="login_input"
          v-model="dataToSql.password"
          @keyup.enter="login"
        />
      </div>

      <div class="btn_box">
        <div class="btn" @click="openWindow('close')">关闭</div>
        <div class="btn" @click="login('open')">登录</div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import store from "@/store/index";
import api from "@/api/index";

import axios from "axios";
export default {
  setup() {
    let $router = useRouter();
    let data = reactive({
      select: "WebCollection",
      pass: "",
      user: "",
      input: "",
      number: "",
      select: "PdfNoteList",
    });
    let dataToSql = reactive({
      id: null,
      password: "",
      user_name: "",
      command: "selectByUserName",
    });

    onMounted(() => {
      // goOtherPage("WebCollection");
    });

    const goOtherPage = (name, para_01, para_02) => {
      if (!para_01) {
        $router.push({
          name: name,
        });
      } else {
        $router.push({
          name: name,
          query: {
            para_01: para_01,
            para_02: para_02,
          },
        });
      }
    };

    // 打开新窗口
    const openNewWindow = (para) => {
      const href = $router.resolve({
        //使用resolve
        name: para, //这里是跳转页面的name
      });
      window.open(href.href, "_blank");
    };

    // 打开登录窗口
    const openWindow = (open) => {
      let window = document.querySelector(".login_box");
      if (open == "open") {
        window.style.display = "block";
      } else {
        window.style.display = "none";
      }
    };

    // 登录
    const login = () => {
      if (dataToSql.user_name == "") {
        return alert("用户名为空");
      } else if (dataToSql.password == "") {
        return alert("密码为空");
      }

      axios({
        method: "post",
        url: api.host + api.user,
        headers: "multipart/form-data",
        data: dataToSql,
      }).then((res) => {
        // data.userName = res.data.results[0].user_name
        data.pass = res.data.results[0].password;
        data.user = res.data.results[0];
        if (data.pass == dataToSql.password) {
          store.commit("addUserName", dataToSql.user_name);
          store.commit("addPms_01", data.user.pms_01);
          store.commit("addPms_02", data.user.pms_02);
          store.commit("addPms_03", data.user.pms_03);
          store.commit("addPms_04", data.user.pms_04);
          store.commit("addPms_05", data.user.pms_05);
          store.commit("addPms_06", data.user.pms_06);
          store.commit("addPms_07", data.user.pms_07);
        } else {
          return alert("用户名或密码错误");
        }
      });
      openWindow("close");
    };

    return {
      data,
      goOtherPage,
      openWindow,
      dataToSql,
      login,
      openNewWindow,
    };
  },
};
</script>

<style lang="less">
// 备案
.beianHao {
  position: fixed;
  width: 100%;
  height: 30px;
  left: 0;
  bottom: 0;
  background-color: rgb(240, 240, 240);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  a {
    color: gray;
    text-decoration: none;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.head {
  width: 100%;
  height: 40px;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 99;
  box-shadow: 0 0 3px 3px rgb(200, 200, 200);
  padding: 0 30px;
  @media screen and (max-width: 600px) {
    display: none;
  }
  .myName {
    padding: 0 16px;
    border: none;
    background-color: white;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 30px;
  }
  .dropdown {
    position: relative;
    display: inline-block;

    // border: 1px solid rgb(21, 0, 255);
  }
  .dropbtn {
    padding: 0 16px;
    border: none;
    background-color: white;
    font-weight: bold;
    font-size: 16px;
    // border: 1px solid red;
    margin: 0 10px 0 0;
  }
  .dropbtn:hover {
    color: rgb(214, 120, 13);
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .head_pc {
    display: none;
  }
  .pc {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .head_mobile {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .pc_head {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .mobile_head {
    display: none;
  }
}
.bottom_box {
  @media screen and (min-width: 600px) {
    display: none;
  }
  .box {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 5vh;
    box-shadow: 0 0 2px 2px rgb(200, 200, 200);
    display: flex;
    justify-content: space-around;
    background-color: white;
    align-items: center;
    z-index: 90;

    select {
      padding: 3px 10px;
      border: 2px solid rgb(200, 200, 200);
      border-radius: 5px;
      margin: 0 10px 0 0;
    }
  }
}

.head_mobile {
  .box {
    position: fixed;
    top: 0;
    left: 40vw;
    right: 5vw;
    height: 6vh;
    display: flex;
    align-items: center;
    padding: 2vh 0;
    justify-content: space-between;
    // background-color: white;

    select {
      padding: 3px 5px;
      border: 2px solid rgb(200, 200, 200);
      border-radius: 5px;
      margin: 0 10px 0 0;
    }
  }
}

// 登录框
.login_box {
  display: none;
  .login {
    position: fixed;
    width: 400px;
    height: 300px;
    border: 3px solid gray;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .login_input {
      width: 100%;
      padding: 10px;
      border: 2px solid gray;
      border-radius: 5px;
      background-color: rgb(214, 214, 214);
      margin: 5px 0 15px 0;
    }
    .btn_box {
      display: flex;
      justify-content: space-around;
      margin: 20px 0 0 0;
      width: 80%;
      .btn {
        padding: 5px 15px;
        border: 2px solid rgb(165, 165, 165);
        background-color: rgb(240, 240, 240);
        border-radius: 5px;
      }
      .btn:hover {
        color: red;
      }
    }
  }
}
.exit_box {
  display: none;
  // display: flex;
  // justify-content: space-around;
}

// 搜索框
.search_box {
  width: 40vw;
  display: flex;
  justify-content: left;
  background-color: rgb(255, 255, 255);
  align-items: center;

  @media screen and (min-width: 600px) {
    width: 400px;
    margin: 0 0 0 30px;
  }
  input {
    border: 2px solid rgb(200, 200, 200);
    padding: 5px;
    border-radius: 5px;
    width: 30vw;
    @media screen and (min-width: 600px) {
      width: 70%;
    }
  }
  .text {
    padding: 5px 10px;
  }
}
.window {
  display: none;
  .box {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      // width: 100%;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.window_mobile {
  display: none;
  .box {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      // width: 100%;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.font_bold {
  font-weight: bold;
  white-space: pre-line;
}
.font_gray {
  color: gray;
}
.font_tag {
  font-size: 15px;
}
.font_title {
  font-size: 25px;
  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
}
.margin_bottom {
  margin: 0 0 5px 0;
}
.font_italic {
  font-style: italic;
  font-family: "Times New Roman", Times, serif;
}
.con {
  padding: 100px 0 60px 0;
  @media screen and (max-width: 600px) {
    padding: 8vh 0 5vh 0;
  }
}
.mobile_head {
  width: 100%;
  .box {
    box-shadow: 0 0 3px 3px rgb(200, 200, 200);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 6vh;
    display: flex;
    align-items: center;
    padding: 2vh 5vw;
    width: 100%;
    justify-content: space-between;
    background-color: white;
  }
}

.head_pc {
  .box {
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    height: 50px;
    display: flex;
    padding: 0 80px;
    align-items: center;
    z-index: 90;
    background-color: white;
    select {
      padding: 5px 20px;
      border: 2px solid rgb(200, 200, 200);
      border-radius: 5px;
      margin: 0 0 0 30px;
    }
    .item {
      margin: 0 20px 0 0;
    }
  }
}
</style>
